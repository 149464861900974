import { ClientPositionEnum, ClientRoleEnum, LanguageEnum, SciencePharmaRegistrationType } from '@acd-loreal/data';
import { IPharmacyUserDataItem } from './pharmacy/pharmacy-user-data-item';

export interface IUser {
  agreement_email: boolean;
  agreement_phone: boolean;
  agreement_sms: boolean;
  email: string;
  firstname: string;
  lastname: string;
  mobile: string;
  pharmacies: IPharmacyUserDataItem[];
  position: ClientPositionEnum;
  position_identity: string;
  uuid: string;
  medical_chamber: {
    id: number;
    name: string;
  };
  show_welcome_video: boolean;
  show_instruction_video: boolean;

  country: { country: LanguageEnum; name: string };
  // mapped
  role: ClientRoleEnum;
  vichy_mega_brand: SciencePharmaRegistrationType | null;
  vichy_mega_brand_offline_count?: number;
  has_social_media_poll_filled: boolean;
  has_c16_poll_filled: boolean;
  poll_social_media: {
    pool_answer_1: string;
    url_inst: string;
    url_fb: string;
    url_tik_tok: string;
    url_you_tube: string;
    url_blog: string;
    url_other_name: string;
    url_other: string;
    pool_answer_3: string;
    pool_answer_4: string;
    pool_answer_5: string;
  };
}

export function isUser(user: any): user is IUser {
  return user.pharmacies !== undefined;
}

export interface ILorealUser {
  uuid: string;
  firstname: string;
  lastname: string;
  email: string;
  mobile: string;
  position: ClientPositionEnum;
  show_instruction_video: boolean;
  country: { country: LanguageEnum; name: string };
}

export function isLorealUser(user: any): user is ILorealUser {
  return user.pharmacies === undefined;
}

export enum POLL_FILLED {
  REJECTED = 'rejected',
  FILLED = 'filled',
}
