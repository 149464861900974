import { ChangeDetectorRef, Directive, inject } from '@angular/core';
import Swiper from 'swiper';

@Directive()
export abstract class AcdSwiperComponent {
  public blockActionsButton = false;
  public maxIndex = false;
  protected _maxIndexValue = 0;

  protected readonly _changeDetectorRef = inject(ChangeDetectorRef);

  public calculateSlides(event: Swiper): void {
    this.blockActionsButton = (window.innerWidth > 1058 ? 1058 : window.innerWidth) > Number(event['virtualSize']);
    if (event.currentBreakpoint !== 'max') {
      this._maxIndexValue = event.slides.length - 3;
    }
    this._changeDetectorRef.markForCheck();
  }

  public slideChange(event): void {
    this.maxIndex = this._maxIndexValue > 0 && event.activeIndex >= this._maxIndexValue;
  }
}
